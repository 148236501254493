import api from '@/api/lib';
import Collection from '@/models/Collection';
import toSearchFilters from '@/api/lib/helpers/toSearchFilters';
import removeNull from '@/api/lib/helpers/removeNull';
import removeUndefined from '@/api/lib/helpers/removeUndefined';
import extractFilter from '@/api/lib/helpers/extractFilter';
import Auth from '@/models/Auth';
import filterMatchesItem from '@/lib/helpers/filterMatchesItem';
import clock from '@/lib/clock';
import mock from '@/lib/helpers/mock';
import isLive from '@/lib/helpers/isLive';
import store from '@/store';
import ApiResponseFormatEnum from '@/enums/api/responseFormat';

const shouldMockApi = () => {
    return isLive() ? false : localStorage.getItem('mock_api') === 'true';
};

export class BaseApi {
    static api = api;
    static instances = new Map();

    static resource = null;
    static version = 'v1';
    static mockItemCount = 10;

    static ApiResponseFormatEnum = ApiResponseFormatEnum;
    get ApiResponseFormatEnum() {
        return ApiResponseFormatEnum;
    }

    /**
     * Create a new API instance.
     * @param {Object} model
     * @returns {Object} instance
     */
    constructor(model = null) {
        if (model === false) {
            this.model = null;
        } else {
            this.model = model;
        }

        if (BaseApi.instances.has(this.getInstanceKey())) {
            return BaseApi.instances.get(this.getInstanceKey());
        }

        this.mockStorageKey = `mock_${this.constructor.resource}`;
        this.mockSchema = this._getMockSchema();
        this.methods = this.endpoints;

        if (BaseApi.shouldMockApi()) {
            for (const key in this.methods) {
                if (this.mock_endpoints[`${key}`]) {
                    this.methods[`${key}`] = this.mock_endpoints[`${key}`];
                }
            }
        }

        BaseApi.instances.set(this.getInstanceKey(), this);
    }

    getInstanceKey() {
        return `${this.constructor.resource}:${this.constructor.version || 'v1'}:${
            this.model ? 'with-model' : 'no-model'
        }`;
    }

    destroy() {
        BaseApi.instances.delete(this.getInstanceKey());
    }

    get base_url() {
        let url = '/';

        if (this.constructor.version) {
            url += `${this.constructor.version}/`;
        }

        if (this.constructor.resource) {
            url += this.constructor.resource;
        }

        return url;
    }

    /**
     * Get the endpoints for this API.
     * @returns {Object} endpoints
     * @example
     * {
     *    ...super.endpoints,
     *    test: this.test.bind(this),
     * }
     */
    get endpoints() {
        return {
            get: this.get.bind(this),
            index: this.index.bind(this),
            patch: this.patch.bind(this),
            post: this.post.bind(this),
            put: this.put.bind(this),
            remove: this.remove.bind(this),
            copy: this.copy.bind(this),
            search: this.search.bind(this),
            statistics: this.statistics.bind(this)
        };
    }

    get mock_endpoints() {
        if (!this.model) {
            return {};
        }

        if (!Object.keys(this.mockSchema).length) {
            return {};
        }

        return {
            get: this.get_mock.bind(this),
            index: this.index_mock.bind(this),
            patch: this.patch_mock.bind(this),
            post: this.post_mock.bind(this),
            put: this.put_mock.bind(this),
            remove: this.remove_mock.bind(this),
            copy: this.copy_mock.bind(this),
            search: this.search_mock.bind(this)
        };
    }

    /**
     * Get a specific thing.
     *
     * @param {String} id
     * @returns {Object} response
     */
    async get(id, params = {}, config = { base_url: null }) {
        let response = await api.get(`${config.base_url || this.base_url}/${id}`, params, config);

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            response.data = this._filterGetResponseData(response.data);

            this._updateItemInCollections(id, response.data);

            this._insertModel(response.data, config.responseFormat || this.ApiResponseFormatEnum.DETAIL);
        });
    }

    async get_mock(id) {
        const data = this._getMocked().find(item => item.id === id);

        if (!data) {
            return {
                status: 'fail',
                message: 'Not found'
            };
        }

        let response = {
            status: 'success',
            data
        };

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            response.data = this._filterGetResponseData(response.data);

            this._updateItemInCollections(id, response.data);
            this._insertModel(response.data);
        });
    }

    /**
     * Retrieve a list of things.
     *
     * @param {Number} offset
     * @param {Number} limit
     * @param {Object} sort_by
     * @param {Object} filters
     *
     * @returns {Object} response
     */
    async index(offset = null, limit = null, sort_by = null, filters = {}, config = { base_url: null }) {
        let params = {};

        if (offset) {
            params.offset = offset;
        }

        if (limit) {
            params.limit = limit;
        }

        if (sort_by) {
            params.sort_by = sort_by;
        }

        if (filters) {
            params = { ...params, ...removeUndefined(filters) };
        }

        let response = await api.get(config.base_url || this.base_url, params, config);

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            response.data = this._filterIndexResponseData(response.data);

            this._insertModels(response.data);
        });
    }

    async index_mock(offset = 0, limit = 100, sort_by = null, filters = {}) {
        let data = this._getMocked();

        if (!data.length) {
            data = await this._generateMocked(this.constructor.mockItemCount);
        }

        data = this._filterMocked(data, filters);

        if (offset !== null && limit !== null) {
            data = data.slice(offset, offset + limit);
        }

        if (sort_by) {
            // todo;
        }

        let response = {
            status: 'success',
            data,
            metadata: {
                total: data.length,
                limit,
                offset
            }
        };

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            response.data = this._filterIndexResponseData(response.data);

            this._insertModels(response.data);
        });
    }

    /**
     * Create one new thing.
     * @param {String} id
     * @param {Object} data
     * @returns {Object} response
     */
    async patch(id, data, config = { base_url: null }) {
        data = this._filterPatchData(data);
        let response = await api.patch(`${config.base_url || this.base_url}/${id}`, data, config);

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            response.data = this._filterPatchResponseData(response.data);

            this._updateItemInCollections(id, response.data);
            this._insertModel(response.data, this.ApiResponseFormatEnum.DETAIL);
        });
    }

    async patch_mock(id, data) {
        const items = this._getMocked();
        const index = items.findIndex(item => item.id === id);

        if (index === -1) {
            return {
                status: 'fail',
                message: 'Not found'
            };
        }

        items[`${index}`] = {
            ...items[`${index}`],
            ...data,
            last_updated_by: this._auth()?.user || null,
            last_updated_by_id: this._auth()?.user?.id || null,
            last_updated: BaseApi.clock().toISOString()
        };

        this._saveMocked(items);

        let response = {
            status: 'success',
            data: items[`${index}`]
        };

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            response.data = this._filterPatchResponseData(response.data);

            this._updateItemInCollections(id, response.data);
            this._insertModel(response.data, this.ApiResponseFormatEnum.DETAIL);
        });
    }

    /**
     * Create one new thing.
     * @param {Object} data
     * @returns {Object} response
     */
    async post(data, config = { base_url: null }) {
        data = this._filterPostData(data);
        let response = await api.post(config.base_url || this.base_url, data, config);

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            response.data = this._filterPostResponseData(response.data);

            this._clearCollection();
            this._insertModel(response.data, this.ApiResponseFormatEnum.DETAIL);
        });
    }

    async post_mock(data) {
        const generated = (await this._generateMocked(1))[0];

        const item = {
            ...data,
            id: generated.id,
            created_by: this._auth()?.user || null,
            created_by_id: this._auth()?.user?.id || null,
            created_at: BaseApi.clock().toISOString()
        };

        return await this.patch_mock(item.id, item);
    }

    /**
     * Update a specific thing.
     * @param {Object} data
     * @returns {Object} response
     */
    async put(data = { id: null }, config = { base_url: null }) {
        data = this._filterPutData(data);
        let response = await api.put(`${config.base_url || this.base_url}/${data.id}`, data, config);

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            response.data = this._filterPutResponseData(response.data);

            this._updateItemInCollections(data.id, response.data);
            this._insertModel(response.data, this.ApiResponseFormatEnum.DETAIL);
        });
    }

    async put_mock(data) {
        const items = this._getMocked();
        const index = items.findIndex(item => item.id === data.id);

        if (index === -1) {
            return {
                status: 'fail',
                message: 'Not found'
            };
        }

        items[`${index}`] = {
            ...items[`${index}`],
            ...data,
            last_updated_by: this._auth()?.user || null,
            last_updated_by_id: this._auth()?.user?.id || null,
            last_updated: BaseApi.clock().toISOString()
        };

        this._saveMocked(items);

        let response = {
            status: 'success',
            data: items[`${index}`]
        };

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            response.data = this._filterPutResponseData(response.data);

            this._updateItemInCollections(data.id, response.data);
            this._insertModel(response.data, this.ApiResponseFormatEnum.DETAIL);
        });
    }

    /**
     * Delete (soft) a specific thing.
     * @param {String} id
     * @returns {Object} response
     */
    async remove(id, config = { base_url: null }) {
        let response = await api.remove(`${config.base_url || this.base_url}/${id}`, config);

        return this._respond(response, () => {
            this._removeItemInCollections(id);
            this.model.delete(id);
        });
    }

    async remove_mock(id) {
        const items = this._getMocked();
        const index = items.findIndex(item => item.id === id);

        if (index === -1) {
            return {
                status: 'fail',
                message: 'Not found'
            };
        }

        items.splice(index, 1);
        this._saveMocked(items);

        let response = {
            status: 'success'
        };

        return this._respond(response, () => {
            this._removeItemInCollections(id);
            this.model.delete(id);
        });
    }

    /**
     * Copy a specific thing.
     * @param {String} id
     * @returns {Object} response
     */
    async copy(id, config = { base_url: null }) {
        let response = await api.post(`${config.base_url || this.base_url}/${id}/copy`, null, config);

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            response.data = this._filterPostResponseData(response.data);

            this._clearCollection();
            this._insertModel(response.data, this.ApiResponseFormatEnum.DETAIL);
        });
    }

    async copy_mock(id) {
        const items = this._getMocked();
        const index = items.findIndex(item => item.id === id);

        if (index === -1) {
            return {
                status: 'fail',
                message: 'Not found'
            };
        }

        const item = {
            ...items[`${index}`],
            id: null,
            name: `${items[`${index}`].name} (Copy)`
        };

        items.push(item);

        this._saveMocked(items);

        let response = {
            status: 'success',
            data: item
        };

        return this._respond(response);
    }

    /**
     * Search for a specific thing.
     * @param {Object} criteria
     * @param {Object} filters
     * @returns {Object} response
     */
    async search(criteria, filters = {}, config = { base_url: null }) {
        let response = await api.get(
            `${config.base_url || this.base_url}`,
            this._toSearchFilters(criteria, filters),
            config
        );

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            response.data = this._filterSearchResponseData(response.data);

            this._insertModels(response.data);
        });
    }

    async search_mock(criteria, filters = {}) {
        let data = this._getMocked();

        if (!data.length) {
            return {
                status: 'success',
                data: []
            };
        }

        data = this._filterMocked(data, filters);

        let response = {
            status: 'success',
            data,
            metadata: {
                total: data.length,
                limit: data.length,
                offset: 0
            }
        };

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            response.data = this._filterSearchResponseData(response.data);

            this._insertModels(response.data);
        });
    }

    /**
     * Retrieve statistics for a specific thing.
     * @param {Object} filters
     * @returns {Object} response
     */
    async statistics(filters = {}, config = { base_url: null }) {
        let response = await api.get(`${config.base_url || this.base_url}/statistics`, filters, config);

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            response.data = this._filterStatisticsResponseData(response.data);
        });
    }

    _respond(response, callback = () => {}) {
        if (response && response.status === 'success') {
            callback();
        }
        return response;
    }
    _resetResponseTypeOnChildren(item) {
        if (!item) return item;
        if (typeof item !== 'object') return item;
        if (Array.isArray(item)) {
            return item.map(item => this._resetResponseTypeOnChildren(item));
        }
        if (!('id' in item)) return item;

        for (const itemKey in item) {
            if (typeof item[`${itemKey}`] === 'object') {
                item[`${itemKey}`] = this._resetResponseTypeOnChildren(item[`${itemKey}`]);
            }
        }

        item.$responseFormat = 0;

        return item;
    }

    _insertModel(item, $responseFormat = 0) {
        if (!item || !this.model) return;

        if (!item.id && item.created_id) {
            item = { ...item, id: item.created_id };
            delete item.created_id;
        }

        item = this._resetResponseTypeOnChildren(item);

        this.model.insertOrUpdate({ data: { ...item, $responseFormat } });
    }
    _insertModels(data, model = null) {
        model = model || this.model;
        if (!data || !model) return;

        data.forEach(item => {
            if (!model.find(item.id)) {
                model.insert({ data: { ...item, $responseFormat: 0 } });
            }
        });
    }
    _updateItemInCollections(id, data) {
        if (!id || !data) return;

        Collection.updateItemInCollections(id, data);
    }
    _removeItemInCollections(id) {
        if (!id) return;

        Collection.removeItemInCollections(id);
    }
    _clearCollection(type) {
        type = type || this.model?.name || null;

        if (!type) return;

        Collection.delete(collection => collection.type.includes(type));
    }
    _auth() {
        return Auth();
    }
    _toSearchFilters(criteria, filters = {}, filterKey = 'name', filterOperator = 'CONTAINS') {
        return toSearchFilters(criteria, filters, filterKey, filterOperator);
    }
    _filterGetResponseData(data) {
        return data;
    }
    _filterIndexResponseData(data) {
        return data;
    }
    _filterPatchResponseData(data) {
        return data;
    }
    _filterPostResponseData(data) {
        return data;
    }
    _filterPutResponseData(data) {
        return data;
    }
    _filterSearchResponseData(data) {
        return data;
    }
    _filterStatisticsResponseData(data) {
        return data;
    }
    _filterResponseData(data) {
        if (!data) return data;

        if (Array.isArray(data)) {
            return data.map(item => this._filterResponseData(item));
        } else if (typeof data === 'object' && data !== null) {
            // Extract relationship IDs from objects and add them to the parent object as a new field.
            for (const key in data) {
                if (typeof data[`${key}`] === 'object' && data[`${key}`] !== null) {
                    if (data[`${key}`].id && !data[`${key}_id`]) {
                        data[`${key}_id`] = data[`${key}`].id;
                    }
                    this._filterResponseData(data[`${key}`]);
                }
            }

            // Add the ID to the object if it's missing.
            if (data?.created_id) {
                data.id = data.created_id;
                delete data.created_id;
            }
        }

        return data;
    }

    _filterSaveData(data) {
        return data;
    }

    _filterPostData(data) {
        return this._filterSaveData(data);
    }

    _filterPutData(data) {
        return this._filterSaveData(data);
    }

    _filterPatchData(data) {
        return this._filterSaveData(data);
    }

    //

    _saveMocked(items) {
        localStorage.setItem(this.mockStorageKey, JSON.stringify(items));
    }
    _getMocked() {
        const items = localStorage.getItem(this.mockStorageKey);

        if (!items) {
            this._saveMocked([]);
        }

        return items ? JSON.parse(items) : [];
    }
    _getMockSchema() {
        if (!this.model) {
            return {};
        }

        return this.model.mock();
    }
    async _generateMocked(limit = 1) {
        const items = this._getMocked();
        const generated = await BaseApi.mock(this.mockSchema, limit);

        items.push(...generated);
        this._saveMocked(items);

        return generated;
    }
    _filterMocked(items, filters = {}) {
        if (!items.length || !Object.keys(filters)) {
            return items;
        }

        return items.filter(item => {
            let match = true;

            for (const key in filters) {
                const filterMatch = filterMatchesItem(item, key, filters[`${key}`]);

                if (!filterMatch) {
                    match = false;
                    break;
                }
            }

            return match;
        });
    }

    abortSearch(group) {
        const requests = store.getters['requests/findAll']({});
        const url = `${this.base_url}`;

        requests.forEach(match => {
            if (
                !match.persistent &&
                match.method === 'GET' &&
                match.status === 'pending' &&
                match.url.includes(url) &&
                match.abortGroup === group
            ) {
                store.dispatch('requests/aborted', match.id);
            }
        });
    }

    static toSearchFilters = toSearchFilters;
    static removeNull = removeNull;
    static removeUndefined = removeUndefined;
    static extractFilter = extractFilter;
    static mock = mock;
    static clock = clock;
    static auth = Auth;
    static shouldMockApi = shouldMockApi;
}

export default BaseApi;
