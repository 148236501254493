import BaseApi from '@/api/BaseApi';

export class OfferApi extends BaseApi {
    static resource = 'offers';

    get endpoints() {
        return {
            ...super.endpoints,
            postInfoRequest: this.postInfoRequest.bind(this),
            getFees: this.getFees.bind(this),
            getApplicationForm: this.getApplicationForm.bind(this)
        };
    }

    _filterSaveData(data) {
        if (data && Array.isArray(data.application_form_required_signatures)) {
            data.application_form_required_signatures = data.application_form_required_signatures.map(participant => ({
                ...participant,
                adobesign_application_role: participant.role,
                type: participant.organisation_role
            }));
        }

        return data;
    }

    _filterResponseData(data) {
        data = super._filterResponseData(data);

        if (data && Array.isArray(data)) {
            data = data.map(item => this._filterResponseData(item));
        }

        if (data && Array.isArray(data.product_offers)) {
            data.product_offers = data.product_offers.map(productOffer => {
                productOffer.product_id = productOffer.product.id;
                productOffer.offer_id = data.id;

                return productOffer;
            });
        }

        if (data && Array.isArray(data.application_form_required_signatures)) {
            data.application_form_required_signatures = data.application_form_required_signatures.map(participant => ({
                ...participant,
                role: participant.role || participant.adobesign_application_role,
                organisation_role: participant.organisation_role || participant.type
            }));
        }

        return data;
    }

    async postInfoRequest(offerId, message) {
        const response = await BaseApi.api.post(`${this.base_url}/${offerId}/request-info`, { message });
        return this._respond(response);
    }

    async getFees(offerId) {
        const response = await BaseApi.api.get(`${this.base_url}/${offerId}/fees`);
        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            this._clearCollection('Fees');
        });
    }

    async getApplicationForm(offerId) {
        let url = `${this.base_url}`;

        if (offerId) {
            url += `/${offerId}`;
        }

        url += '/application-form';

        return await BaseApi.api.get(
            url,
            {},
            {
                headers: {
                    Accept: 'application/pdf'
                },
                responseType: 'blob',
                returnFullResponse: true
            }
        );
    }
}

export default OfferApi;
